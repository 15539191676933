<template>
	<div class="home">
		<table-dollarrate></table-dollarrate>
  </div>
</template>

<script>
import tableDollarrate from '@/components/pages/maint/dollarrate/tableDollarrate.vue';

export default {
	components: {
		'table-dollarrate': tableDollarrate,
	}
};
</script>

<style>

</style>
